<template>
    <div class="language-selector-component">
        <div class="select-content" :class="{'show': showList}">
            <div class="lang-content source" @click.stop="showListEvent('source')">
                <div class="lang">
                    <template v-if="autoFlag">
                        <template v-if="autoLang">{{$t('component.LanguageSelector_autoLang_result', {name: autoLang.name})}}</template>
                        <template v-else>{{$t('component.LanguageSelector_autoLang_label')}}</template>
                    </template>
                    <template v-else>{{source.name}}</template>
                </div>
                <div class="suffix-icon">
                    <i class="el-icon-arrow-down"></i>
                </div>
            </div>
            <div class="change-icon" @click="exchangeLanguage" :disabled="open && autoFlag && autoLang === null">
                <el-tooltip :content="$t('component.LanguageSelector_button_exchang')" placement="top">
                    <svg-icon name="ib-transition"></svg-icon>
                </el-tooltip>
            </div>
            <div class="lang-content target" @click.stop="showListEvent('target')">
                <div class="lang">{{target.name}}</div>
                <div class="suffix-icon">
                    <i class="el-icon-arrow-down"></i>
                </div>
            </div>
            <!-- (listType==='source'&&key===target.key)|| -->
            <div class="lang-list" :class="listType">
                <!-- <template v-if="open">
                    <div class="lang-griad padding-b-10 margin-b-10 border-b" >
                        
                    </div>
                </template> -->
                <div class="search-content">
                    <el-input class="flex-1" v-model="searchKeyword" @input="searchInputEvent" clearable :placeholder="$t('component.LanguageSelector_search_input_placeholder')" prefix-icon="el-icon-search" ></el-input>
                    <div class="lang-griad" style="display: flex; justify-content: end;">
                        <template v-for="(item, index) in frequentlyLanguages">
                            <div class="item" :key="index" @click="itemClickEvent(item)">{{item.name}}</div>
                        </template>
                    </div>
                </div>
                <div class="index-bar">
                    <template v-for="(char, index) in indexList">
                        <div class="item" :class="{'active': selectedIndex === char}" @click="indexClickEvent(char)" :key="index">{{char}}</div>
                    </template>
                </div>
                <div class="lang-wapper">
                    <div class="lang-griad">
                        <template v-if="listType === 'source'">
                            <div class="item" :class="{'active': autoFlag}" @click="itemClickEvent('auto')">{{$t('component.LanguageSelector_autoLang_label')}}</div>
                        </template>
                        <template v-for="(value,key) in languageDict">
                            <div :key="key" 
                                :class="['item', 
                                    open && listType==='source' ? (!autoFlag && key === activeLang ? 'active' : '') : (key === activeLang ? 'active' : ''),
                                    selectedIndex === value.firstLetter ? 'highlight-group' : '',
                                ]" 
                                @click="itemClickEvent(value)"
                            >{{value.name}}</div> <!-- :disabled="(listType==='target' && source && key === source.key)" -->
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import config from '@/utils/config';
import { commonApi } from '@/utils/api';
export default {
    props: {
        default: {
            type: Object,
            default: {
                source: '',
                target: 'zh',
            }
        },
        auto: {
            type: Boolean,
            default: true,
        },
        lang: {
            type: String,
            default: null,
        },
        open: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    data(){
        return{
            searchKeyword: '',
            languageDict: {},
            searchTimer: null,
            showList: false,
            listType: null,
            activeLang: 'zh',
            source: {key: 'zh', name: '中文', shortName: '中'},
            target: {key: 'en', name: '英语', shortName: '英'},
            autoFlag: true,
            autoLang: null,
            indexList: [],
            selectedIndex: '',
            frequentlyLanguages: [],
        }
    },
    created(){
        this.initLanguageDict();
        this.languageDict = this.LANGUAGE_DICT
        this.source = this.default.source ? this.LANGUAGE_DICT[this.default.source] : '';
        this.target = this.LANGUAGE_DICT[this.default.target];
        this.autoFlag = this.auto;
        this.autoLang = this.lang ? this.LANGUAGE_DICT[this.lang] : null;
        this.frequentlyLanguages = [
            {"key": "zh", "name": this.$t('component.LanguageSelector_source_language_name'), "shortName": this.$t('component.LanguageSelector_source_language_short_name'), "firstLetter": "Z"},
            {"key": "en", "name": this.$t('component.LanguageSelector_target_language_name'), "shortName": this.$t('component.LanguageSelector_target_language_short_name'), "firstLetter": "Y"},
        ]
    },
    watch: {
        default (to, from){
            this.source = to.source ? this.LANGUAGE_DICT[to.source] : '';
            this.target = this.LANGUAGE_DICT[to.target];
        },
        auto (newVal, oldVal){
            this.autoFlag = newVal;
        }, 
        lang (newVal, oldVal){
            this.autoLang = newVal ? this.LANGUAGE_DICT[newVal] : null;
        }
    },
    methods:{
        showListEvent(type){
            if(type === 'source'){
                if(this.showList && this.listType === 'source'){
                    this.showList = false;
                }else{
                    this.showList = true;
                }
                this.activeLang = this.source ? this.source.key : '';
            }else{
                if(this.showList && this.listType === 'target'){
                    this.showList = false;
                }else{
                    this.showList = true;
                }
                this.activeLang = this.target.key;
            }
            this.listType = type;
            this.selectedIndex = '';
        },
        itemClickEvent(item){
            if(item === 'auto'){
                this.autoFlag = true;
                this.$emit('change',{auto: this.autoFlag, lang: this.autoLang, source: this.source, target: this.target});
            }else{
                if(this.listType ==='source'){
                    this.source = item;
                    if(item.key === this.target.key){
                        if(item.key === 'zh'){
                            this.target = {key: 'en', name: this.$t('component.LanguageSelector_target_language_name'), shortName: this.$t('component.LanguageSelector_target_language_short_name')};
                        }else{
                            this.target = {key: 'zh', name: this.$t('component.LanguageSelector_source_language_name'), shortName: this.$t('component.LanguageSelector_source_language_short_name')};
                        }
                    }
                    this.autoFlag = false;
                    this.autoLang = null;
                }else{
                    this.target = item;
                    // console.log(this.target, this.autoFlag, this.autoLang);
                }
                this.activeLang = item.key;
                this.$emit('change',{auto: this.autoFlag, lang: this.autoLang, source: this.source, target: this.target});
            }
            this.showList = false;
            this.selectedIndex = '';
        },
        exchangeLanguage(){
            let tmpSource = this.source;
            if(this.autoFlag && this.autoLang){
                tmpSource = this.autoLang;
            }
            let tmpTarget = this.target;
            this.source = tmpTarget;
            this.target = tmpSource;
            this.autoFlag = false;
            this.autoLang = null;
            this.$emit('change',{auto: this.autoFlag, lang: this.autoLang, source: this.source, target: this.target});
        },
        getValues(){
            return {
                auto: this.autoFlag,
                lang: this.autoLang ? this.autoLang.key : null,
                source: this.source.key,
                target: this.target.key,
            }
        },
        getObjectValues(){
            return {
                auto: this.autoFlag,
                lang: this.autoLang,
                source: this.source,
                target: this.target,
            }
        },
        indexClickEvent(char){
            this.selectedIndex = char;
            this.$nextTick(() => {
                let element = $('.highlight-group')[0];
                this.$scrollTo(element, 30, {container: '.lang-wapper', offset: 0,});
            });
        },
        searchInputEvent(val) {
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                this.initLanguageDict();
            }, 500);
        },
        async initLanguageDict(){
            let url = `${commonApi.queryLanguageDict}?keyword=${this.searchKeyword}`;
            const res = await this.$ajax.get(url);
            this.languageDict = res.data;
            this.selectedIndex = '';
            if(!this.searchKeyword) {
                let indexSet = new Set();
                for(let lang in this.languageDict) {
                    indexSet.add(this.languageDict[lang].firstLetter);
                }
                this.indexList = Array.from(indexSet).sort();
            }
            
        },
    },
    mounted(){
        let self = this;
        $(document).on('mousedown',e=>{
            let pNode = $(e.target).parents('.lang-content');
            let lNode = $(e.target).hasClass('lang-list') ? $(e.target) : $(e.target).parents('.lang-list');
            if(pNode.length === 0 && lNode.length === 0){
                self.showList = false;
                self.listType = null;
                self.selectedIndex = '';
            }
        });
        
    }
}
</script>
<style lang="scss" scoped>
$lang-content-width: 200px;
$lang-content-height: 40px;
$lang-list-width: 860px;
$lang-list-height: 697px;
.language-selector-component{
    display: flex;
    position: relative;
    > .select-content{
        display: flex;
        .lang-content{
            min-width: $lang-content-width;
            height: $lang-content-height;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            background-color: #ffffff;
            border-radius: 4px;
            padding: 0 15px;
            cursor: pointer;
            
            > .lang{
                font-size: 14px;
                overflow:hidden;
                white-space:nowrap;
                text-overflow:clip;
                margin-right: 6px;
            }
            > .suffix-icon{
                width:15px; 
                font-size: 14px;
            }
        }
        .change-icon{
            width: 44px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            &[disabled],&:disabled{
                opacity: .5;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
        .lang-list{
            display: none;
            flex-direction: column;
            width: $lang-list-width;
            height: $lang-list-height;
            overflow: hidden;
            // padding: 20px 40px;
            position: absolute;
            top: $lang-content-height + 5px;
            left: -50%;
            right: 0;
            min-height: 400px;
            background-color: #ffffff;
            // border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 10px;
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
            z-index: 5;
            &.source{
                // border-radius:0 4px 4px 4px;
            }
            &.target{
                // border-radius:4px 0 4px 4px;
            }
            .search-content {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                padding: 0px 20px;
                box-sizing: border-box;
                border-bottom: 1px solid #e5e5e5;
                ::v-deep .el-input { 
                    .el-input__inner{
                        border: none;
                        padding-left: 38px;
                    }
                    .el-input__prefix {
                        font-size: 18px;
                    }
                }
            }
            .index-bar {
                display: flex;
                align-items: center;
                padding: 10px 30px;
                .item {
                    font-size: 14px;
                    color: #999;
                    margin-left: 15px;
                    cursor: pointer;
                    &.active {
                        color: #00B670;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            .lang-wapper {
                flex: 1;
                overflow-y: auto;
                padding: 5px 30px 20px 30px;
            }
            .lang-griad{
                display: grid;
                grid-gap: 10px 0;
                grid-template-columns: repeat(auto-fill,minmax(120px,1fr));
                .item{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    padding: 0px 15px;
                    min-height: 32px;
                    line-height: 1.5;
                    border-radius: 6px;
                    cursor: pointer;
                    &[disabled],&:disabled{
                        cursor: not-allowed;
                        pointer-events: none;
                        opacity: .5;
                    }
                    &.highlight-group {
                        background-color: #e9f8f2;
                    }
                    &.active{
                        color: #00B670;
                    }
                    &:hover{
                       background-color: #F2F2F2; 
                    }
                    
                }
            }
        }
       
        &.show{
            .lang-list{
                display: flex;
            }
            
        }

    }
}
</style>
